@font-face{
  font-family:"SantanderHeadlineW05-Rg";
  src:url("./SantanderHeadlineW05-Rg.eot?#iefix");
  src:url("./SantanderHeadlineW05-Rg.eot?#iefix") format("eot"),url("./SantanderHeadlineW05-Rg.woff2") format("woff2"),url("./SantanderHeadlineW05-Rg.woff") format("woff"),url("./SantanderHeadlineW05-Rg.ttf") format("truetype");
}
@font-face{
  font-family:"SantanderHeadlineW05-Italic";
  src:url("./SantanderHeadlineW05-Italic.eot?#iefix");
  src:url("./SantanderHeadlineW05-Italic.eot?#iefix") format("eot"),url("./SantanderHeadlineW05-Italic.woff2") format("woff2"),url("./SantanderHeadlineW05-Italic.woff") format("woff"),url("./SantanderHeadlineW05-Italic.ttf") format("truetype");
}
@font-face{
  font-family:"SantanderHeadlineW05-Bold";
  src:url("./SantanderHeadlineW05-Bold.eot?#iefix");
  src:url("./SantanderHeadlineW05-Bold.eot?#iefix") format("eot"),url("./SantanderHeadlineW05-Bold.woff2") format("woff2"),url("./SantanderHeadlineW05-Bold.woff") format("woff"),url("./SantanderHeadlineW05-Bold.ttf") format("truetype");
}
@font-face{
  font-family:"SantanderHeadlineW05-BoldIt";
  src:url("./SantanderHeadlineW05-BoldIt.eot?#iefix");
  src:url("./SantanderHeadlineW05-BoldIt.eot?#iefix") format("eot"),url("./SantanderHeadlineW05-BoldIt.woff2") format("woff2"),url("./SantanderHeadlineW05-BoldIt.woff") format("woff"),url("./SantanderHeadlineW05-BoldIt.ttf") format("truetype");
}
@font-face{
  font-family:"SantanderTextW05-Light";
  src:url("./SantanderTextW05-Light.eot?#iefix");
  src:url("./SantanderTextW05-Light.eot?#iefix") format("eot"),url("./SantanderTextW05-Light.woff2") format("woff2"),url("./SantanderTextW05-Light.woff") format("woff"),url("./SantanderTextW05-Light.ttf") format("truetype");
}
@font-face{
  font-family:"SantanderTextW05-LightIt";
  src:url("./SantanderTextW05-LightIt.eot?#iefix");
  src:url("./SantanderTextW05-LightIt.eot?#iefix") format("eot"),url("./SantanderTextW05-LightIt.woff2") format("woff2"),url("./SantanderTextW05-LightIt.woff") format("woff"),url("./SantanderTextW05-LightIt.ttf") format("truetype");
}
@font-face{
  font-family:"SantanderTextW05-Regular";
  src:url("./SantanderTextW05-Regular.eot?#iefix");
  src:url("./SantanderTextW05-Regular.eot?#iefix") format("eot"),url("./SantanderTextW05-Regular.woff2") format("woff2"),url("./SantanderTextW05-Regular.woff") format("woff"),url("./SantanderTextW05-Regular.ttf") format("truetype");
}
@font-face{
  font-family:"SantanderTextW05-Italic";
  src:url("./SantanderTextW05-Italic.eot?#iefix");
  src:url("./SantanderTextW05-Italic.eot?#iefix") format("eot"),url("./SantanderTextW05-Italic.woff2") format("woff2"),url("./SantanderTextW05-Italic.woff") format("woff"),url("./SantanderTextW05-Italic.ttf") format("truetype");
}
@font-face{
  font-family:"SantanderTextW05-Bold";
  src:url("./SantanderTextW05-Bold.eot?#iefix");
  src:url("./SantanderTextW05-Bold.eot?#iefix") format("eot"),url("./SantanderTextW05-Bold.woff2") format("woff2"),url("./SantanderTextW05-Bold.woff") format("woff"),url("./SantanderTextW05-Bold.ttf") format("truetype");
}
@font-face{
  font-family:"SantanderTextW05-BoldItalic";
  src:url("./SantanderTextW05-BoldItalic.eot?#iefix");
  src:url("./SantanderTextW05-BoldItalic.eot?#iefix") format("eot"),url("./SantanderTextW05-BoldItalic.woff2") format("woff2"),url("./SantanderTextW05-BoldItalic.woff") format("woff"),url("./SantanderTextW05-BoldItalic.ttf") format("truetype");
}