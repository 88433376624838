@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes showElement {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes loaderFadeOut {
  0% {
    opacity: 1;
  }
  99.9% {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  100% {
    padding: 0;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

@keyframes activeActionCtaPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(#fff, .75);
  }

  75% {
    box-shadow: 0 0 0 10px rgba(#fff, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(#fff, 0);
  }
}