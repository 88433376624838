@font-face {
  font-family: 'Athletics';
  src: url('./Athletics Regular WEB/Athletics-Regular.woff') format('woff'),
  url('./Athletics Regular WEB/Athletics-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Athletics';
  src: url('./Athletics Bold WEB/Athletics-Bold.woff') format('woff'),
  url('./Athletics Bold WEB/Athletics-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}