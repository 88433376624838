@import "src/styles/animations";
@import "src/styles/fonts";


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background-color: #f4f7fc!important;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

label {
  margin: 0;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

img {
  display: block;
}

button {
  outline: none !important;

  &:active {
    outline: none;
  }
}

.MuiPaper-root {
  &.MuiDialog-paperFullWidth {
    width: calc(100% - 10px);
  }

  &.MuiDialog-paperScrollPaper {
    max-height: calc(100% - 10px);
  }

  &.MuiDialog-paper {
    margin: 5px;
  }
}